@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  outline: none !important;
}

::-webkit-scrollbar {
  margin-top: 200px;
  padding-top: 200px;

  background: none;
  width: 0.375rem;
  height: 0.375rem;
}
::-webkit-scrollbar-track {
  margin-top: 20px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #d0d5dd;
}
table {
  transform: translateZ(0);
  will-change: transform;
}

html,
body,
#root,
.App {
  position: relative;
  height: 100svh;
  width: 100%;
  overflow: hidden;
}

.radial-gradient-border {
  border-radius: 16px; 
  border: 1px solid transparent;
  background: radial-gradient(circle, #F4F8FF 0%, #FFFFFF 100%) padding-box, 
              conic-gradient(rgba(37, 101, 200, 0) 0deg 90deg, rgba(180, 209, 254, 0.5) , rgba(37, 101, 200, 0) 180deg 270deg, rgba(180, 209, 254, 0.5) , rgba(37, 101, 200, 0)) border-box;
}

.radial-gradient-border-orange {
  border-radius: 16px; 
  border: 1px solid transparent;
  background:  radial-gradient(50% 50% at 50% 50%, #FFFAEB 0%, #FFFFFF 100%) padding-box, 
  conic-gradient(rgba(37, 101, 200, 0) 0deg 90deg, rgba(180, 209, 254, 0.5) , rgba(37, 101, 200, 0) 180deg 270deg, rgba(180, 209, 254, 0.5) , rgba(37, 101, 200, 0)) border-box
}





.radial-gradient-border::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 16px; 
  border: 1px solid transparent;
  background: conic-gradient(rgba(37, 101, 200, 0) 0deg 90deg, rgba(180, 209, 254, 0.5) , rgba(37, 101, 200, 0) 180deg 270deg, rgba(180, 209, 254, 0.5) , rgba(37, 101, 200, 0));
  z-index: -1;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #12b76a;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #d92d20;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(
    to right,
    ,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
